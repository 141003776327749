import { isPlatformBrowser } from '@angular/common';
import {
  ApplicationRef,
  Inject,
  Injectable,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { first, interval, concat, filter, tap } from 'rxjs';
import { InfoDialogComponent } from '../shared/components/info-dialog/info-dialog.component';
import { NgZone } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  dialogRef?: MatDialogRef<InfoDialogComponent>;
  public markedForUpdate = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialog: MatDialog,
    private zone: NgZone,
    router: Router,
    route: ActivatedRoute
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const swu = inject(SwUpdate);

      if (!swu.isEnabled) {
        return;
      }

      router.events.subscribe((event) => {
        if (event instanceof NavigationStart && this.markedForUpdate) {
          console.info('Marked for update, reloading app.');
          window.location.href = event.url;
        }
      });

      swu.versionUpdates
        .pipe(filter((event) => event.type === 'VERSION_READY'))
        .subscribe(() => {
          this.markForUpdate();
        });

      swu.unrecoverable.subscribe(() => {
        this.forceUserToReload(
          `Došlo je do greške prilikom ažuriranja aplikacije. Molimo Vas da osvežite stranicu.`
        );
      });

      const appRef = inject(ApplicationRef);
      const appIsStable$ = appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );

      const updateCheckInterval$ = interval(60 * 1000); // 1 minute
      const whenStableOnInterval$ = concat(appIsStable$, updateCheckInterval$);

      whenStableOnInterval$.subscribe(async () => {
        try {
          const updateFound = await swu.checkForUpdate();
          if (updateFound) {
            this.markForUpdate();
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }

  markForUpdate() {
    this.markedForUpdate = true;
  }

  forceUserToReload(message: string) {
    if (!!this.dialogRef) {
      return;
    }

    this.zone.run(() => {
      this.dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          message,
        },
      });
      this.dialogRef.afterClosed().subscribe(() => {
        document.location.reload();
      });
    });
  }
}
