import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { AuthService } from './auth.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { RouteUtils } from '../utils/route.utils';
import { SsrUtilis } from '../utils/ssr.utils';
import { Crisp } from 'crisp-sdk-web';

@Injectable({
  providedIn: 'root',
})
export class CrispChatService {
  private authService = inject(AuthService);
  private angulartics2 = inject(Angulartics2);
  private platformID = inject(PLATFORM_ID);
  private isLauncherHiddenPage = true;
  private readonly CRISP_WEBSITE_ID = '2c3f05a0-facc-49d1-abfa-574f52285545';
  public get CRISP_GUEST_TOKEN_ID() {
    if (localStorage.getItem('CRISP_GUEST_TOKEN_ID') === null) {
      localStorage.setItem('CRISP_GUEST_TOKEN_ID', crypto.randomUUID());
    }
    return localStorage.getItem('CRISP_GUEST_TOKEN_ID');
  }

  constructor() {
    SsrUtilis.runInBrowserOnly(() => {
      Crisp.configure(this.CRISP_WEBSITE_ID, {
        locale: 'sr',
        safeMode: true,
        autoload: false,
      });

      Crisp.chat.onChatOpened(() => {
        this.angulartics2.eventTrack.next({
          action: 'support_chat_opened',
          properties: { category: 'support_chat' },
        });
      });

      Crisp.chat.onChatClosed(() => {
        if (this.isLauncherHiddenPage) {
          this.chatHide();
        }
      });

      this.authService.userLoggedOut$.subscribe(() => this.sessionReset());
      this.authService.userLoggedIn$.subscribe(() => {
        this.setSessionInfo();
        Crisp.session.reset();
      });
    }, this.platformID);
  }

  public refresh(event: NavigationEnd, route: ActivatedRoute): void {
    while (route.firstChild) {
      route = route.firstChild;
    }
    const url = event?.urlAfterRedirects ?? '';
    const launcherHiddenPagesRegex = ['/korpa'];
    this.isLauncherHiddenPage = launcherHiddenPagesRegex.some((x) =>
      url.match(x)
    );
    const crispChatEnabled =
      RouteUtils.getDataFromAncestors(route, 'crispChat') &&
      !this.isLauncherHiddenPage;

    this.setSessionInfo();

    if (crispChatEnabled) {
      this.chatShow();
    } else {
      this.chatHide();
    }
  }

  setSessionInfo(): void {
    const user = this.authService.getUser();
    if (user) {
      Crisp.setTokenId(user.crispTokenId as string);
      Crisp.user.setEmail(user.email as string);
    } else {
      Crisp.setTokenId(this.CRISP_GUEST_TOKEN_ID as string);
    }
  }

  chatOpen(): void {
    SsrUtilis.runInBrowserOnly(() => {
      this.chatShow();
      Crisp.chat.open();
    }, this.platformID);
  }

  chatShow(): void {
    SsrUtilis.runInBrowserOnly(() => {
      Crisp.chat.show();
    }, this.platformID);
  }

  chatHide(): void {
    SsrUtilis.runInBrowserOnly(() => {
      Crisp.chat.hide();
    }, this.platformID);
  }

  private sessionReset(): void {
    SsrUtilis.runInBrowserOnly(() => {
      localStorage.removeItem('CRISP_GUEST_TOKEN_ID');
      Crisp.setTokenId(this.CRISP_GUEST_TOKEN_ID as string);
      Crisp.session.reset();
    }, this.platformID);
  }
}
