import { Injectable } from '@angular/core';
import { TeadsPixelService } from './tracking-pixels/teads-pixel.service';
import { FacebookPixelService } from './tracking-pixels/facebook-pixel.service';
import { GooglePixelService } from './tracking-pixels/google-pixel.service';

export interface ConversionTrackingEvent {
  price: number; // Optional
  currency: string; // Optional, ISO 4217
  conversionType: string;
  email: string; // Optional
  items: Item[]; // Optional
}

export interface Item {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConversionTrackingService {
  constructor(
    private teads: TeadsPixelService,
    private fb: FacebookPixelService,
    private google: GooglePixelService
  ) {}

  trackLead(event: Partial<ConversionTrackingEvent>) {
    this.teads.trackLead(event);
    this.fb.trackLead(event);
    this.google.trackLead(event);
  }

  trackCompleteRegistration(event: Partial<ConversionTrackingEvent>) {
    this.teads.trackCompleteRegistration(event);
    this.fb.trackCompleteRegistration(event);
    this.google.trackCompleteRegistration(event);
  }

  trackViewContent(event: Partial<ConversionTrackingEvent>) {
    this.teads.trackViewContent(event);
    this.fb.trackViewContent(event);
    this.google.trackViewContent(event);
  }

  trackAddToCart(event: Partial<ConversionTrackingEvent>) {
    this.teads.trackAddToCart(event);
    this.fb.trackAddToCart(event);
    this.google.trackAddToCart(event);
  }

  trackInitiateCheckout(event: Partial<ConversionTrackingEvent>) {
    this.teads.trackInitiateCheckout(event);
    this.fb.trackInitiateCheckout(event);
    this.google.trackInitiateCheckout(event);
  }

  trackPurchase(event: Partial<ConversionTrackingEvent>) {
    this.teads.trackPurchase(event);
    this.fb.trackPurchase(event);
    this.google.trackPurchase(event);
  }

  trackNewsletterSubscription(event: Partial<ConversionTrackingEvent>) {
    this.google.trackNewsletterSubscription(event);
  }
}
