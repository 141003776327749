import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import {
  BrowserModule,
  provideClientHydration,
  withNoHttpTransferCache,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material/snack-bar';
import { Observable, forkJoin, map } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { GlobalErrorHandler } from './shared/services/global-error-handler';
import { PlatformLocation } from '@angular/common';
import srLatn from '@angular/common/locales/sr-Latn';
import { registerLocaleData } from '@angular/common';
registerLocaleData(srLatn);

DefaultValueAccessor.prototype.registerOnChange = function (
  fn: (_: string | null) => void
): void {
  this.onChange = (value: string | null) => {
    fn(value === '' ? null : value);
  };
};

class MultipleTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public prefixes: string[],
    public suffix: string = '.json'
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    return forkJoin(
      this.prefixes.map((prefix) =>
        this.http.get(`${prefix}${lang}${this.suffix}`)
      )
    ).pipe(
      map((response) => {
        return response.reduce((a, b) => {
          return { ...a, ...b };
        });
      })
    );
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(
  http: HttpClient,
  platformLocation: PlatformLocation
) {
  const baseHref = platformLocation.getBaseHrefFromDOM();
  return new MultipleTranslateHttpLoader(http, [
    `${baseHref}assets/i18n/`,
    '/api/assets/i18n/shared/',
  ]);
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'sr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, PlatformLocation],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'sr-Latn' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 1500,
        horizontalPostion: 'center',
        verticalPosition: 'top',
        panelClass: ['text-center', '!mt-[72px]'],
      },
    },
    provideClientHydration(
      withNoHttpTransferCache()
    ),
  ],
})
export class AppModule {}
