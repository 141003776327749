import { ActivatedRoute, Router } from '@angular/router';

export class RouteUtils {
  public static getDataFromAncestors = (
    route: ActivatedRoute,
    key: string
  ): any => {
    if (route.snapshot.data[key]) {
      return route.snapshot.data[key];
    } else if (route.parent) {
      return RouteUtils.getDataFromAncestors(route.parent, key);
    }
  };

  public static getParamFromAncestors = (
    route: ActivatedRoute,
    key: string
  ): any => {
    if (route.snapshot.params[key]) {
      return route.snapshot.params[key];
    } else if (route.parent) {
      return RouteUtils.getParamFromAncestors(route.parent, key);
    }
  };

  public static getQueryParamFromAncestors = (
    route: ActivatedRoute,
    key: string
  ): any => {
    if (route.snapshot.queryParams[key]) {
      return route.snapshot.queryParams[key];
    } else if (route.parent) {
      return RouteUtils.getParamFromAncestors(route.parent, key);
    }
  };

  public static getRouteWithoutParams = (
    router: Router
  ): any => {
    let urlTree = router.parseUrl(router.url);
    urlTree.queryParams = {}; 
    urlTree.fragment = null;
    return urlTree.toString();
  };
}
