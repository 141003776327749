/**
 * Coolivery.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum OrderStatus {
  PaymentPending = 'PaymentPending',
  OrderAccepted = 'OrderAccepted',
  Rejected = 'Rejected',
  PaymentFailed = 'PaymentFailed',
  Processing = 'Processing',
  OnHold = 'OnHold',
  Processed = 'Processed',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
  Refunded = 'Refunded',
  Returned = 'Returned',
  Exchanged = 'Exchanged',
  Backorder = 'Backorder',
}
