import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ConversionTrackingEvent } from '../conversion-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  w: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.w = window as any;
    }
  }

  trackLead(event: Partial<ConversionTrackingEvent>) {
    if (this.w && this.w.fbq) {
      this.digestMessage(event.email!).then((hashedEmail) => {
        this.w.fbq('track', 'Lead', {em: hashedEmail});
      });
    }
  }

  trackCompleteRegistration(event: Partial<ConversionTrackingEvent>) {
    if (this.w && this.w.fbq) {
      this.digestMessage(event.email!).then((hashedEmail) => {
        this.w.fbq('track', 'CompleteRegistration', {em: hashedEmail});
      });
    }
  }

  trackViewContent(event: Partial<ConversionTrackingEvent>) {
    if (this.w && this.w.fbq) {
      this.w.fbq('track', 'ViewContent', {content_name: event.items?.[0].name, currency: event.currency, value: event.price});
    }
  }

  trackAddToCart(event: Partial<ConversionTrackingEvent>) {
    if (this.w && this.w.fbq) {
      this.w.fbq('track', 'AddToCart', {content_name: event.items?.[0].name, currency: event.currency, value: event.price});
    }
  }

  trackInitiateCheckout(event: Partial<ConversionTrackingEvent>) {
    if (this.w && this.w.fbq) {
      this.w.fbq('track', 'InitiateCheckout');
    }
  }

  trackPurchase(event: Partial<ConversionTrackingEvent>) {
    if (this.w && this.w.fbq) {
      this.digestMessage(event.email!).then((hashedEmail) => {
        this.w.fbq('track', 'Purchase', {currency: event.currency, value: event.price, em: hashedEmail});
      });
    }
  }

  async digestMessage(message: string) {
    message = message.trim().toLowerCase();
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // convert bytes to hex string
    return hashHex;
  }
}
