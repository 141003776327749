import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ConversionTrackingEvent } from '../conversion-tracking.service';

interface TeadsConversionEvent extends ConversionTrackingEvent {
  name: string; // Optional
  price: number; // Optional
  currency: string; // Optional, ISO 4217
  conversionType: string;
}

@Injectable({
  providedIn: 'root',
})
export class TeadsPixelService {
  w: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.w = window as any;
    }
  }

  private trackConversion(event: Partial<TeadsConversionEvent>) {
    if (this.w && this.w.teads_e) {
      this.w.teads_e.push(event);
    }
  }

  trackLead(event: Partial<ConversionTrackingEvent>) {
    this.trackConversion({
      conversionType: 'Lead',
    });
  }

  trackCompleteRegistration(event: Partial<ConversionTrackingEvent>) {
    this.trackConversion({
      conversionType: 'CompleteRegistration',
    });
  }

  trackViewContent(event: Partial<ConversionTrackingEvent>) {
    this.trackConversion({
      name: event.items?.[0].name,
      conversionType: 'ViewContent',
    });
  }

  trackAddToCart(event: Partial<ConversionTrackingEvent>) {
    this.trackConversion({
      name: event.items?.[0].name,
      currency: event.currency,
      price: event.price,
      conversionType: 'AddToCart',
    });
  }

  trackInitiateCheckout(event: Partial<ConversionTrackingEvent>) {
    this.trackConversion({
      currency: event.currency,
      price: event.price,
      conversionType: 'InitiateCheckout',
    });
  }

  trackPurchase(event: Partial<ConversionTrackingEvent>) {
    this.trackConversion({
      currency: event.currency,
      price: event.price,
      conversionType: 'Purchase',
    });
  }
}
