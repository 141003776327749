/**
 * Coolivery.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum OrderStateTrigger {
  Accept = 'Accept',
  Fail = 'Fail',
  Process = 'Process',
  BackToAccept = 'BackToAccept',
  Finish = 'Finish',
  BackToProcessing = 'BackToProcessing',
  Ship = 'Ship',
  ConfirmDelivery = 'ConfirmDelivery',
  PutOnHold = 'PutOnHold',
  Cancel = 'Cancel',
}
